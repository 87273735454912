<template>
  <div id="CompletedOrder">
    <section v-if="order_id" class="hero is-primary is-large">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            <b-icon icon="truck-check" size="is-large" type="is-white" />
          </h1>

          <h1 class="title">{{ $t("order_complete.success.title") }}</h1>
          <h2 class="subtitle">
            {{ $t("order_complete.success.subtitle") }}
            {{ order_id }}
            {{ $t("order_complete.success.subtitle_more") }}
          </h2>
        </div>
      </div>
    </section>
    <section v-else class="hero is-white is-large">
      <div v-if="!isLoading" class="hero-body">
        <div class="container">
          <h1 class="title">
            <b-icon icon="cart-remove" size="is-large" type="is-danger" />
          </h1>
          <h1 class="title">{{ $t("order_complete.fail.title") }}</h1>
          <b-button type="is-primary" @click="navigateToCategoryCollection">
            {{ $t("btn.continue_shoping") }}
          </b-button>
        </div>
      </div>
      <div v-else class="hero-body">
        <div class="container">
          <h1 class="title">
            <b-icon icon="cart-arrow-right" size="is-large" type="is-danger" />
          </h1>
          <h1 class="title">{{ $t("order_complete.pending.title") }}</h1>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import CheckoutApiManager from "@/api/CheckoutApiManager";

export default {
  name: "CompletedOrder",
  data() {
    return {
      order_id: null,
      isLoading: true
    };
  },
  computed: {
    ...mapState(["charge"])
  },
  methods: {
    ...mapMutations({
      removeCart: "REMOVE_CART",
      removeCharge: "REMOVE_CHARGE"
    }),
    validatePaymentStatus: async function() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      if (this.charge.cart_id) {
        const params = {
          charge_id: this.charge.charge_id
        };
        const response = await CheckoutApiManager.requestCheckPaymentStatus(
          this.charge.cart_id,
          params
        );
        switch (response.code) {
          case "0":
            this.removeCharge();
            this.order_id = response.data.order_id;
            break;

          default:
            this.removeCart();
            break;
        }
      } else {
        this.removeCart();
      }
      loadingComponent.close();
      this.isLoading = false;
    },
    navigateToCategoryCollection: function() {
      this.$router.push({
        name: "collection"
      });
    }
  },
  mounted() {
    this.validatePaymentStatus();
  }
};
</script>

<style></style>
